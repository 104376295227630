<template>
  <div id="mapContainer">
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from "leaflet";

// Erforderlicher Hack, damit die Marker geladen werden.
// https://stackoverflow.com/a/50866199/551176
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


import '@bower_components/PowerPan/dist/leaflet.mousecoordinate.css'
import "@bower_components/PowerPan/dist/leaflet.mousecoordinate.min"

//Source: Overview mini map Plugin: https://github.com/Norkart/Leaflet-MiniMap
import 'leaflet-minimap/dist/Control.MiniMap.min.css'
import 'leaflet-minimap'

//Source: Grouped Layer Plugin: https://github.com/ismyrnow/leaflet-groupedlayercontrol
import 'leaflet-groupedlayercontrol/dist/leaflet.groupedlayercontrol.min.css'
import 'leaflet-groupedlayercontrol'

//Source: https://github.com/codeofsumit/leaflet.pm
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import '@geoman-io/leaflet-geoman-free';

import "./tactical-sign-operator.css"
import "./tactical-sign-operator.js"

export default {
  name: "MapComponent",
  data() {
    return {
      center: [51.163361, 10.447683],
      bounds: L.latLngBounds(
          [46.82056146689723874, 5.16222996807976298],
          [55.50657769018049947, 15.74887898408043796]),
      zoom: 8,
    }
  },
  methods: {
    setupLeafletMap() {
      /******************************************BASEMAPS******************************************/
      var topplusopen = new L.TileLayer('/mapproxy/wmts/topplusopen/webmercator/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '&copy; Bundesamt für Kartographie und Geodäsie (' + new Date().getFullYear() +
            '), Datenquellen: <a href="http://sg.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.pdf" target="_blank">http://sg.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.pdf</a>',
      });
      var topplusopen_grau = new L.TileLayer('/mapproxy/wmts/topplusopen_grau/webmercator/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '&copy; Bundesamt für Kartographie und Geodäsie (' + new Date().getFullYear() +
            '), Datenquellen: <a href="http://sg.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.pdf" target="_blank">http://sg.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.pdf</a>',
      });
      var opentopomap = new L.TileLayer('/mapproxy/wmts/opentopomap/webmercator/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: 'Kartendaten: &copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>-Mitwirkende, <a href="http://viewfinderpanoramas.org">SRTM</a> | Kartendarstellung: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
      });

      var stamentiles = new L.TileLayer('/mapproxy/wmts/stamentiles/webmercator/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under<br>' +
            '<a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>.' +
            'Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, ' +
            '<a href="http://www.openstreetmap.org/copyright">ODbL</a>.'
      });
      var stamentilesterrain = new L.TileLayer('/mapproxy/wmts/stamentilesterrain/webmercator/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under<br>' +
            '<a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>.' +
            'Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, ' +
            '<a href="http://www.openstreetmap.org/copyright">ODbL</a>.'
      });

      var esri_worldstreetmap = new L.TileLayer('/mapproxy/wmts/esri_worldstreetmap/webmercator/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: 'KTiles &copy; Esri &mdash; ' +
            'Source: <a href="https://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer" target="_blank">https://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer</a>',
      });
      var esri_worldtoptmap = new L.TileLayer('/mapproxy/wmts/esri_worldtoptmap/webmercator/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: 'KTiles &copy; Esri &mdash; ' +
            'Source: <a href="https://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer" target="_blank">https://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer</a>',
      });
      var esri_worldimagerymap = new L.TileLayer('/mapproxy/wmts/esri_worldimagerymap/webmercator/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: 'KTiles &copy; Esri &mdash; ' +
            'Source: <a href="https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer" target="_blank">https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer</a>',
      });

      var basemaps = {
        "BKG TopPlusOpen": topplusopen,
        "BKG TopPlusOpen (Graustufen)": topplusopen_grau,
        "OpenTopoMap": opentopomap,
        "Stamen": stamentiles,
        "Stamen Terrain": stamentilesterrain,
        "ESRI WorldStreetMap": esri_worldstreetmap,
        "ESRI WorldTopoMap": esri_worldtoptmap,
        "ESRI WorldImagery": esri_worldimagerymap,
      };

      /****************************************Generate Map****************************************/
      const mapDiv = L.map("mapContainer", {
        center: this.center,
        zoom: this.zoom,
        minZoom: 5,
        maxBounds: this.bounds,
        layers: [topplusopen],
      });

      /******************************************OVERLAYS******************************************/
      var DWDWarnungen = new L.tileLayer.wms('/mapproxy/wmts/dwdlayerwarnungen/webmercator/{z}/{x}/{y}.png', {
        opacity: 0.7,
        attribution: '<br> Deutscher Wetterdienst &mdash; ' +
            'Source: <a href="https://maps.dwd.de/geoserver/web/">DWD Geoserver Website',

      });
      var DWDKaelte = new L.tileLayer.wms('/mapproxy/wmts/dwdgefuehltekaelte/webmercator/{z}/{x}/{y}.png', {
        opacity: 0.5,
        attribution: '<br> Deutscher Wetterdienst &mdash; ' +
            'Source: <a href="https://maps.dwd.de/geoserver/web/">DWD Geoserver Website',
      });

      var DWDNiederschlag = new L.tileLayer.wms('/mapproxy/wmts/dwdniederschlag/webmercator/{z}/{x}/{y}.png', {
        opacity: 0.5,
        attribution: '<br> Deutscher Wetterdienst &mdash; ' +
            'Source: <a href="https://maps.dwd.de/geoserver/web/">DWD Geoserver Website',
      });

      var DKreise = new L.tileLayer.wms('/mapproxy/wmts/dkreise/webmercator/{z}/{x}/{y}.png', {
        opacity: 1,
        attribution: '<br> geoportal.de&mdash; ' +
            'Source: <a href="https://sg.geodatenzentrum.de/wms_vg250-ew?">https://sg.geodatenzentrum.de/wms_vg250-ew?',
      });

      var DRegierungsbezirk = new L.tileLayer.wms('/mapproxy/wmts/dregierungsbezirk/webmercator/{z}/{x}/{y}.png', {
        opacity: 1,
        attribution: '<br> geoportal.de &mdash; ' +
            'Source: <a href="https://sg.geodatenzentrum.de/wms_vg250-ew?">https://sg.geodatenzentrum.de/wms_vg250-ew?',
      });
      var DGemeinde = new L.tileLayer.wms('/mapproxy/wmts/dgemeinde/webmercator/{z}/{x}/{y}.png', {
        opacity: 1,
        attribution: '<br> geoportal.de &mdash; ' +
            'Source: <a href="https://sg.geodatenzentrum.de/wms_vg250-ew?">https://sg.geodatenzentrum.de/wms_vg250-ew?',
      });

      var mgrs_grid = new L.TileLayer('/mapproxy/wmts/mgrsgrid/webmercator/{z}/{x}/{y}.png', {
        maxZoom: 18,
      });


      //Point overlay with Popup for TZ Signs
      //TODO: static to dynamic via mouseklick plus menu
      var LeafIcon = L.Icon.extend({
        options: {
          iconSize: [50, 50],
          iconAnchor: [0, 0],
          popupAnchor: [25, 20],
        }
      });

      L.icon = function (options) {
        return new L.Icon(options);
      };
      //Grouping of the different overlays
      var groupoverlays = {
        "Overlays": {
          "DWD Warnungen Landkreise": DWDWarnungen,
          "DWD Gefuelte Kaelte": DWDKaelte,
          "DWD Niederschlag": DWDNiederschlag,
          "Kreise": DKreise,
          "Regierungsbezirk": DRegierungsbezirk,
          "Gemeinde": DGemeinde,
        },
        "MGRS": {
          "MGRS-Gitter": mgrs_grid,
        },
        "Markers": {},
      };
      console.log(groupoverlays);

      L.control.tacticalSign({
        position: 'topleft',
        rootOverLayer: groupoverlays
      }).addTo(mapDiv);


      /***************************************MAP - Instance***************************************/
      //add layer switch control
      L.control.groupedLayers(basemaps, groupoverlays).addTo(mapDiv);

      new L.control.scale({
        imperial: false
      }).addTo(mapDiv)

      new L.control.mouseCoordinate({
        utm: true,
        utmref: true,
        gps: true,
        gpsLong: true,
        qth: true
      }).addTo(mapDiv)

      /* Has to be extra for better performance (Minimap Request)*/
      var esri_worldtoptmap2 = new L.TileLayer('/mapproxy/wmts/esri_worldtoptmap/webmercator/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: 'KTiles &copy; Esri &mdash; ' +
            'Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012',
      });

      new L.Control.MiniMap(esri_worldtoptmap2, {
        toggleDisplay: true,
        minimized: false,
        position: 'bottomleft'
      }).addTo(mapDiv);


      //define Drawing toolbar options
      var options = {
        position: 'topleft', // toolbar position, options are 'topleft', 'topright', 'bottomleft', 'bottomright'
        drawMarker: true, // adds button to draw markers
        drawPolyline: true, // adds button to draw a polyline
        drawRectangle: true, // adds button to draw a rectangle
        drawPolygon: true, // adds button to draw a polygon
        drawCircle: true, // adds button to draw a cricle
        cutPolygon: true, // adds button to cut a hole in a polygon
        editMode: true, // adds button to toggle edit mode for all layers
        removalMode: true, // adds a button to remove layers
      };

      // add leaflet.pm controls to the map
      mapDiv.pm.addControls(options);


    },
  },
  mounted() {
    this.setupLeafletMap();
  }
}
</script>

<style scoped>
#mapContainer {
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
</style>
