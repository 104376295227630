<template>
  <div id="app">
    <div>
      <MapComponent></MapComponent>
    </div>
  </div>
</template>

<script>
import MapComponent from "@/components/MapComponent";

export default {
  name: 'App',
  components: {
    MapComponent
  }
}
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
}

#app {
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
  display: grid;
  padding: 1rem;
  box-sizing: border-box;
}
</style>